(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/overlay/views/overlay.js') >= 0) return;  svs.modules.push('/components/components/overlay/views/overlay.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.overlay=_cmps.overlay||{};
_cmps.overlay.templates=_cmps.overlay.templates||{};
svs.components.overlay.templates.overlay = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div id=\"overlay-container\" class=\"js-overlay-container overlay-container\">\n    <div class=\"js-overlay-content overlay-content\" tabindex=\"0\"></div>\n</div>";
},"useData":true});
Handlebars.partials['components-components-overlay-overlay'] = svs.components.overlay.templates.overlay;
})(svs, Handlebars);


 })(window);