(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/overlay/assets/javascripts/overlay.js') >= 0) return;  svs.modules.push('/components/components/overlay/assets/javascripts/overlay.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.components = svs.components || {};

  var templates = svs.components.overlay && svs.components.overlay.templates;

  svs.components.Overlay = function() {
    var className = null;
    var containerEl = null;
    var containerId = 'overlay-container';
    var containerSelector = '.js-overlay-container';
    var contentEl = null;
    var contentSelector = '.js-overlay-content';
    var currentView = null;
    var activeElementBeforeOpen = null;

    function setStyle(options) {
      var viewPortHeight;
      var overlayWidth;
      var maxTopMargin = 120; 
      var overlayPanel = $(contentSelector);

      options = options || {};

      if (svs.core.detect.formfactor.mobile()) {
        if (svs.core.detect.orientation.isLandscape()) {
          options.overlayStyle = '95%';
        } else {
          options.overlayStyle = options.overlayStyle || '100%';
        }
      } else {
        options.overlayStyle = options.overlayStyle || '500px';
      }

      if (options.overlayStyle === 'max') {
        overlayWidth = '95%';
      } else if (options.overlayStyle === 'auto') {
        overlayWidth = overlayPanel.outerWidth();
      } else {
        overlayWidth = options.overlayStyle;
      }

      if (options.overflow) {
        overlayPanel.css('overflow', options.overflow);
      }
      overlayPanel
        .css('width', overlayWidth)
        .css('left', '50%')
        .css('margin-left', '-' + overlayPanel.outerWidth() / 2 + 'px');
      if (options.center) {
        overlayPanel.css('top', $(window).height() / 2 - 175 + 'px');
      } else {
        overlayPanel.css(
          'top',
          (overlayPanel.offset().left <= 79 ?
            overlayPanel.offset().left :
            maxTopMargin) + 'px'
        );
      }

      if (options.overlayStyle === 'max') {
        viewPortHeight = $(window).height();
        overlayPanel
          .css('position', 'fixed')
          .css(
            'height',
            (viewPortHeight - overlayPanel.position().top * 2) /
              viewPortHeight *
              100 +
              '%'
          );
      }
    }

    function disposeView() {
      if (currentView) {
        currentView.trigger('hide');
        if (currentView.destroy) {
          currentView.destroy();
        } else if (currentView.dispose) {
          currentView.dispose();
        }
        currentView = null;
      }
      contentEl.html('');
    }

    function setUpAccessibility() {
      var overlayContainer = document.querySelector('.js-overlay-container');
      var focusCatcher = document.createElement('div');
      focusCatcher.className += 'js-overlay-focus-catcher';

      overlayContainer.setAttribute('tabindex', '0');
      focusCatcher.setAttribute('tabindex', '0');
      overlayContainer.appendChild(focusCatcher);

      focusCatcher.addEventListener('focus', setFocus);
      overlayContainer.addEventListener('focus', setFocus);
    }
    function setFocus() {
      var overlayContainer = document.querySelector('.js-overlay-container');
      var focusable = overlayContainer.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      var firstFocusable = focusable[0];
      var lastFocusable = focusable[focusable.length - 2];
      if (document.querySelector('.js-overlay-container') === document.activeElement) {
        lastFocusable.focus();
      } else {
        firstFocusable.focus();
      }
    }

    return {
      templates: templates,
      currentView: function() {
        return currentView;
      },
      contentEl: function() {
        return contentEl;
      },
      show: function(view, locked, options) {
        activeElementBeforeOpen = document.activeElement ? document.activeElement : document.body;
        containerEl = $(containerSelector);
        contentEl = $(contentSelector);

        if (containerEl.length === 0) {
          $('body').append(svs.components.overlay.templates.overlay());

          containerEl = $(containerSelector);
          contentEl = $(contentSelector);
        }
        var self = this;
        containerEl.on('click', function(e) {
          if (e.target.id === containerId && !locked) {
            self.hide();
          }
        });

        if (typeof view !== 'string' && typeof view !== 'object') {
          currentView = new view(options || null);
          contentEl.html(currentView.render().$el);
        } else {
          contentEl.html(view);
        }

        if (options && options.className) {
          className = options.className;
          containerEl.addClass(className);
        }

        containerEl.addClass('overlay-animate-start');
        containerEl.show();

        setTimeout(function() {
          containerEl.addClass('overlay-animate-started');
        }, 10);

        setStyle(options);
        $(window).resize(function() {
          setStyle(options);
        });

        contentEl.focus();
        setUpAccessibility();
      },
      hide: function() {
        activeElementBeforeOpen && activeElementBeforeOpen.focus();
        var overlayPanel;

        document.querySelector('.js-overlay-container') && document.querySelector('.js-overlay-container').removeEventListener('focus', setFocus);
        document.querySelector('.js-overlay-focus-catcher') && document.querySelector('.js-overlay-focus-catcher').removeEventListener('focus', setFocus);

        if (containerEl) {
          overlayPanel = $(contentSelector);
          overlayPanel.removeAttr('style');

          containerEl.hide();
          containerEl.removeClass('overlay-animate-start overlay-animate-started');
          containerEl.off('click');
          if (className) {
            containerEl.removeClass(className);
          }
          disposeView();
          svs.events.publish('hideOverlay');
        }
      }
    };
  };

  svs.components.overlay = new svs.components.Overlay();
})(svs);


 })(window);